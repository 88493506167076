import * as capSvc from './services/capService.js';
import searchRequestModel from './models/searchRequestModel.js'
import searchDisplayModel from "./models/searchDisplayModel.js";
import {footer} from "./searchFooter.js";
import {keyTabNavigation} from "../utilities/navigation.js";

window.loadJobDetail = loadJobDetail;
window.hideCaliNotice = hideCaliNotice;
window.acceptGdpr = acceptGdpr;
capSvc.checkReferral();
let prevBrandId = sessionStorage.getItem('prevBrandId')
let brandId;
brandId = capSvc.getBrand();
capSvc.injectTags("JobSearch");

let direction = "asc"
const columns = [
  {path: "title", name: "Title", justification: "text-left"},
  {path: "id", name: "ID", justification: "text-left"},
  {path: "positionType", name: "Position Type", justification: "text-left"},
  {path: "city", name: "City", justification: "text-left"},
  {path: "stateProvince", name: "State", justification: "text-center"},
  {path: "datePosted", name: "Date Posted", justification: "text-center"}
];
if(sessionStorage.getItem('brandId') === 'staffmarkgroup') {
  columns.push({path: "brandName", name: "Brand", justification: "text-left"})
};

columns.push({path: "isRemote", name: "Remote", justification: "text-center"})

let bodyRequest = null
if(sessionStorage.getItem('bodyRequest') === null || brandId !== prevBrandId){
  console.log('reset search params')
  bodyRequest = searchRequestModel
  sessionStorage.setItem('bodyRequest', JSON.stringify(bodyRequest))
}else{
  bodyRequest = JSON.parse(sessionStorage.getItem('bodyRequest'))
}

let displayData = searchDisplayModel
const searchDiv = document.getElementById("brandBody");

searchDiv.innerHTML =
  `
  <div id="headerRow" class="d-flex justify-content-center">
      <a id="homePage" class="mx-auto" href="${sessionStorage.getItem('homePageUrl')}">
        <img id="applicationLogoHeader" alt="${sessionStorage.getItem('brandName')} Logo" src="${sessionStorage.getItem('logoImageUrl')}" >
      </a>
  </div>
  ${sessionStorage.getItem('headerHtml')}
`

  const tableSearchDisplayDiv = document.createElement("div")
  tableSearchDisplayDiv.setAttribute("id", "searchTableDiv")
  tableSearchDisplayDiv.setAttribute("class", "container clearfix pb-1")

  const searchTable = document.createElement("div")
  searchTable.setAttribute("id", "searchTable")
  searchTable.setAttribute("class", "container")
  tableSearchDisplayDiv.appendChild(searchTable)

  const s = document.createElement("script")
  s.type = 'module'
  s.src = '../assets/js/searchFilter.js'
  s.defer = true
  tableSearchDisplayDiv.appendChild(s)

console.log(bodyRequest)
const jobs = capSvc.getJobs(bodyRequest)
  const jobs2 = jobs.then(res => res.json())
  jobs2.then(json => {
    console.log(json)
    displayData = json
    if (displayData.searchResults !== null) {
      populateSearchTable(displayData)
      footer(displayData.totalResults, bodyRequest["resultsPerPage"], bodyRequest["page"] + 1)
      setSortHeader()
      keyTabNavigation()
    }
  })
  searchDiv.appendChild(tableSearchDisplayDiv)
// jobDescriptionRow
  removePreloader();
  capSvc.addGdprNotice();
  if (!sessionStorage.getItem('cookiesAccepted') || sessionStorage.getItem('cookiesAccepted') === 'false') {

    $('#gdprNotice').show();
  }

function loadJobDetail(jobId, brandCode, stateProvince) {
  let jobDetailUrl
  let queryParams = "";
  let queryCandidateId = sessionStorage.getItem("candidateId");
  let queryReferralAdSourceId = sessionStorage.getItem("referralAdSourceId");
  if(brandCode == 'staffmark' || brandCode == 'staffmarkdrivers'){
      jobDetailUrl = 'https://staffmark.com/jobs/detail/' + jobId + '/'
  } else {
    if (window.location.origin.includes('localhost')) {
      jobDetailUrl = window.location.origin + '/' + brandCode + '/' + jobId + '.html';
    } else {
      jobDetailUrl = window.location.origin + '/jobs/' + brandCode + '/' + jobId;
    }
  }
  if (queryCandidateId !== null){
    if(queryParams !== "") {
      queryParams += "&"
    } else {
      queryParams += "?"
    }
    queryParams += 'cid=' + queryCandidateId
  }
  if (queryReferralAdSourceId !== null){
    if(queryParams !== "") {
      queryParams += "&"
    } else {
      queryParams += "?"
    }
    queryParams += 'source=' + queryReferralAdSourceId
  }
  if(queryParams !== "") {
    jobDetailUrl += queryParams
  }
  console.log('jobDetailUrl: ' + jobDetailUrl)
 if (stateProvince === 'CA') {
    capSvc.showCaliNotice(jobDetailUrl);
  } else {
    window.open(jobDetailUrl, "_self");
  }
}

function populateSearchTable(displayData, pageSize = null) {
  let totalResults = displayData['totalResults'].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  displayData.searchResults.forEach(r => {
    r.isRemote = r.isRemote ? 'Yes' : 'No'
  })
  searchTable.innerHTML = `
        <div id="jobCount" class="d-flex justify-content-center">
            <p id="jobCountDisplay" class="my-2">${totalResults} Positions Found</p>
        </div>
         <div class="">
 					<table id="jobSearchTable" class="table">
					  <thead>
							<tr>
                 ${columns.map(({path, name, justification}) => (
                              `<th class="sorting sorting_asc ${justification}" id="${path}">${name}
                                  <i class="fa fa-chevron-up"></i>
                               </th>`
                )).join('')}
						  </tr>
					 </thead>
					 <tbody>
                ${displayData['searchResults'].map((rowData) => (
                        `<tr tabindex="${0}"
                             class="jobSearchRow"
                             onclick="loadJobDetail(${rowData["id"]},'${rowData["brandCode"]}','${rowData["stateProvince"]}')">
                             ${columns.map(({path, justification}) => (
                                formatData(path, rowData, justification))).join('')}
                        </tr>`)).join('')}
					  </tbody>
				  </table>
         </div>
<!-- Modal -->
        <div class="modal fade" id="caliNoticeModal" tabindex="-1" aria-labelledby="PRIVACY NOTICE TO CALIFORNIA EMPLOYEES AND JOB APPLICANTS REGARDING THE COLLECTION OF PERSONAL INFORMATION" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">PRIVACY NOTICE TO CALIFORNIA EMPLOYEES AND JOB APPLICANTS REGARDING THE COLLECTION OF PERSONAL INFORMATION</h5>
                <button type="button" class="btn btn-close" data-bs-dismiss="modal" aria-label="Close" onclick="hideCaliNotice()" title="Close">X</button>
              </div>
              <div class="modal-body" style="font-size: 14px">
                ${sessionStorage.getItem('ccpaContent')}
              </div>
              <div class="calNoticeFooter">
                <button type="button"
                        class="btn btn-danger"
                        style="float:left"
                        onclick="hideCaliNotice()"
                        title="Close"
                        id="declineCaliNotice"
                        tabindex="0">Decline</button>
                <button type="button"
                        class="btn btn-application"
                        style="float:right"
                        tabindex="0"
                        id="acceptCaliNotice">
                        <a id="caliModalAcceptHref" href="" target="_self" title="Accept" tabindex="0">
                           Accept
                        </a>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center" id="noJobsFound" hidden>
            <p>Sorry, we couldn't find any positions matching your criteria.</p>
        </div>
`
}

function formatData(path, rowData, justification) {
  return (`<td id="${path}" class="${justification}" tabindex="${-1}">
      ${rowData[path]}
    </td>`)
}

export function setSortHeader() {
  const table = document.getElementById('jobSearchTable');

  // Query the headers
  const headers = table.querySelectorAll('th');

  // Loop over the headers
  [].forEach.call(headers, function (header, index) {
    header.addEventListener('click', function () {
      // This function will sort the column
      sortColumn(index, header.id);
    });
  });

  const tableBody = table.querySelector('tbody');
  const rows = tableBody.querySelectorAll('tr');

  // Track sort directions
  let directions = Array.from(headers).map(function (header) {
    return '';
  });

  const sortColumn = function (index, id) {
    if ($('#' + id)[0].classList.contains('sorting_asc')) {
      direction = "asc"
    } else {
      direction = "desc"
    }

    // <i className="fa fa-chevron-up"></i>

    // console.log($('#' + id)[0].querySelector('.fa').classList);

    // // Get the current direction
    // const direction = directions[index] || 'asc';

    switch (id){
      case 'ID':
        searchRequestModel['sortColumn'] = "ERecruitId"
        break
      case 'title':
        searchRequestModel['sortColumn'] = "Title"
        break
      case 'positionType':
        searchRequestModel['sortColumn'] = "PositionType"
        break
      case 'city':
        searchRequestModel['sortColumn'] = "City"
        break
      case 'state':
        searchRequestModel['sortColumn'] = "StateProvince"
        break
      case 'brand':
        searchRequestModel['sortColumn'] = "Brand"
        break
      default:
        searchRequestModel['sortColumn'] = "DatePosted"
        break
    }
    searchRequestModel['sortDirection'] = direction

    capSvc.getJobs(bodyRequest)
      .then(res => res.json())
      .then(json => {
        displayData = json
        bindSearchTable(displayData)
        if (direction === 'asc') {
          $('#' + id)[0].classList.remove('sorting_asc');
          $('#' + id)[0].classList.add('sorting_desc');
          $('#' + id)[0].querySelector('.fa').classList.remove('fa-chevron-up');
          $('#' + id)[0].querySelector('.fa').classList.add('fa-chevron-down');

        } else {
          $('#' + id)[0].classList.remove('sorting_desc');
          $('#' + id)[0].classList.add('sorting_asc');
          $('#' + id)[0].querySelector('.fa').classList.remove('fa-chevron-down');
          $('#' + id)[0].querySelector('.fa').classList.add('fa-chevron-up');
        }
      })

    // Reverse the direction
    directions[index] = direction === 'asc' ? 'desc' : 'asc';
  };
}

export function resetFilter(){
  bodyRequest = searchRequestModel
  sessionStorage.setItem('bodyRequest', JSON.stringify(bodyRequest))
  window.location.reload()
}

export function filterSearch(event) {
  event.preventDefault()
  const state = document.getElementById("inputState").value === "" ? null : document.getElementById("inputState").value
  let distance = null
  let keyword = null
  let eRecruitId = null
  let city = null
  let ZipCode = null
  let isRemote = false
  let resultsPerPage = 10
  if (!document.getElementById("radiusMiles").disabled) {
    distance = document.getElementById("radiusMiles").value
  }
  if (document.getElementById("inputTitle").value !== "") {
    keyword = document.getElementById("inputTitle").value
  }
  if (document.getElementById("inputId").value !== "") {
    eRecruitId = document.getElementById("inputId").value
  }

  if (document.getElementById("inputCity").value) {
    city = document.getElementById("inputCity").value
  }

  if (document.getElementById("inputZipRadius").value !== "") {
    ZipCode = document.getElementById("inputZipRadius").value
  }

  if (document.getElementById("isRemote").checked) {
     isRemote = document.getElementById("isRemote").checked
  }
  sessionStorage.setItem('searchBrandId', $("#inputBrand").val());

  bodyRequest = {
    keywords: keyword,
    campaignCode: null,
    department: null,
    erecruitid: eRecruitId,
    city: city,
    state: state,
    positionType: null,
    postalCode: ZipCode,
    distanceInMiles: distance,
    page: 0,
    resultsPerPage: 10,
    isRemote: isRemote,
    sortColumn: "DatePosted",
    sortDirection: "desc"
  }

sessionStorage.setItem('bodyRequest', JSON.stringify(bodyRequest))
  const jobs = capSvc.getJobs(bodyRequest)
  console.log(jobs)
  const jobs2 = jobs.then(res => res.json())
    jobs2.then(json => {
      console.log(json)
      displayData = json
      bindSearchTable(displayData)
    })
}

export function setPageNumber(pageNumber) {
  bodyRequest["page"] = pageNumber - 1
  sessionStorage.setItem('bodyRequest', JSON.stringify(bodyRequest))
  capSvc.getJobs(bodyRequest)
    .then(res => res.json())
    .then(json => {
      displayData = json
      bindSearchTable(displayData)
    })
}

export function setPageDisplaySize(event) {
  bodyRequest["resultsPerPage"] = event.currentTarget.value
  sessionStorage.setItem('bodyRequest', JSON.stringify(bodyRequest))
  document.getElementsByName("pageDisplaySize").value = bodyRequest["resultsPerPage"]
  capSvc.getJobs(bodyRequest)
    .then(res => res.json())
    .then(json => {
      displayData = json
      bindSearchTable(displayData)
    })
}

function bindSearchTable(displayData) {
  if (displayData.searchResults !== null) {
    if(displayData.searchResults.length > 0){
      populateSearchTable(displayData)
      document.getElementById("tableFooter").remove()
      footer(displayData.totalResults, bodyRequest["resultsPerPage"], bodyRequest["page"] + 1)
      setSortHeader()
      keyTabNavigation()
    }
  }else{
    $('#noJobsFound')[0].removeAttribute('hidden')
    $('#jobCountDisplay').addClass('hidden')
    $('#jobSearchTable').addClass("hidden")
    $('#searchFilter').addClass('hidden')
    document.getElementById("tableFooter").remove()
  }
}

function hideCaliNotice() {
  $("#caliNoticeModal").modal("hide");
}

function acceptGdpr() {
  // console.log('accepted')
  sessionStorage.setItem('cookiesAccepted','true')
  $('#gdprNotice').hide();
}
