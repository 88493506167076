/*
if (location.href.includes('local')) {
  var buttonSrc =  window.location.origin + '/assets/img/recite_speaker_btn.png';
} else {
  var buttonSrc = window.location.origin + '/jobs/assets/img/recite_speaker_btn.png';
}
*/
var buttonSrc = 'https://cdn.staffmarkcms.com/images/recite_speaker_btn.png';
var serviceUrl = "//api.reciteme.com/asset/js?key=";
var serviceKey = "70f6a5f2748f8ffe7c0e22ffce4b2ea83109cebc";
var options = {};  // Options can be added as needed
var autoLoad = false;
var enableFragment = "#reciteEnable";
var loaded = [], frag = !1;
window.location.hash === enableFragment && (frag = !0);


if (location.href.includes('arna')) {
    options['Tracker'] = {
        "Ga": {
            "enabled": true,
            "trackerName": "UA-162188469-4"
        }
    };
} else if (location.href.includes('xpo')) {
    options['Tracker'] = {
        "Ga": {
            "enabled": true,
            "trackerName": "UA-162188469-8"
        }
    };
} else if (location.href.includes('prostaff')) {
    options['Tracker'] = {
        "Ga": {
            "enabled": true,
            "trackerName": "UA-162188469-5"
        }
    };
} else if (location.href.includes('hunter-hamilton')) {
    options['Tracker'] = {
        "Ga": {
            "enabled": true,
            "trackerName": "UA-162188469-7"
        }
    };
} else if (location.href.includes('staffmark')) {
    options['Tracker'] = {
        "Ga": {
            "enabled": true,
            "trackerName": "UA-162188469-3"
        }
    };
} else if (location.href.includes('digital-people')) {
    options['Tracker'] = {
        "Ga": {
            "enabled": true,
            "trackerName": "UA-162188469-6"
        }
    };
} else if (location.href.includes('technical')) {
    options['Tracker'] = {
        "Ga": {
            "enabled": true,
            "trackerName": "UA-162188469-11"
        }
    };
}

function loadScript(c, b) {
    var a = document.createElement("script");
    a.type = "text/javascript";
    a.readyState ? a.onreadystatechange = function () {
        if ("loaded" == a.readyState || "complete" == a.readyState) a.onreadystatechange = null, void 0 != b && b()
    } : void 0 != b && (a.onload = function () {
        b()
    });
    a.src = c;
    document.getElementsByTagName("head")[0].appendChild(a)
}

function _rc(c) {
    c += "=";
    for (var b = document.cookie.split(";"), a = 0; a < b.length; a++) {
        for (var d = b[a]; " " == d.charAt(0);) d = d.substring(1, d.length);
        if (0 == d.indexOf(c)) return d.substring(c.length, d.length)
    }
    return null
}

function loadService(c) {
    for (var b = serviceUrl + serviceKey, a = 0; a < loaded.length; a++) if (loaded[a] == b) return;
    loaded.push(b);
    loadScript(serviceUrl + serviceKey, function () {
        "function" === typeof _reciteLoaded && _reciteLoaded();
        "function" == typeof c && c();
        Recite.load(options);
        Recite.Event.subscribe("Recite:load", function () {
            Recite.enable()
        })
    })
}

"true" == _rc("Recite.Persist") && loadService();
if (autoLoad && "false" != _rc("Recite.Persist") || frag) document.addEventListener ? document.addEventListener("DOMContentLoaded", function (c) {
    loadService()
}) : loadService();

// Floating Button:
function _reciteLoaded() {
  if (reciteMeButton && reciteMeButton.parentNode) {
    reciteMeButton.parentNode.removeChild(reciteMeButton);
  }

  setTimeout(()=>{
    var elements = Recite.Parser.Html.parse(document.getElementById('recite-wrapper'));
    Recite.Event.publish('Recite.Dom.Observor:change', { affectedElements: elements });
  }, 1000)

    Recite.Event.subscribe("Preferences:load", function () {
        Recite.Preferences.set("player.autoplay", false);
    });

    Recite.Event.subscribe("Preferences:reset", function () {
        Recite.Preferences.set("player.autoplay", false);
    });
}

var reciteMeButton;

function createCSS() {
    var css =
            ' #reciteme-button {\n' +
            '            /* Button Styling and Placement */\n' +
            '            width: 215px;\n' +
            '            height: 50px;\n' +
            '            bottom: 25px;\n' +
            '            transition: all .2s ease-in-out;\n' +
            '            background-image: url("' + buttonSrc + '");\n' +
            '            background-repeat: no-repeat;\n' +
            '            background-size: cover;\n' +
            '            border-radius: 7px;\n' +
            '            box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;\n' +
            '            position: fixed;\n' +
            '            z-index: 100;\n' +
            '            cursor: pointer;\n' +
            '        }\n' +
            '        #reciteme-button:hover {\n' +
            '            /* Hover Color */\n' +
            '            transform: scale(1.05);\n' +
            '        }\n' +
            '        #reciteme-button img {\n' +
            '           box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;\n' +
            '            position: absolute;\n' +
            '            top: 50%;\n' +
            '            left: 50%;\n' +
            '            transform: translate(-50%, -50%);\n' +
            '        }',
        head = document.head || document.getElementsByTagName('head')[0],
        style = document.createElement('style');

    head.appendChild(style);

    style.type = 'text/css';
    style.appendChild(document.createTextNode(css));
}

function _createReciteButton() {
    createCSS();
    var buttonParentSelector = 'body';
    var buttonContainer = document.createElement("div");
    var buttonAlt = "Recite Me accessibility and Language Support";
    var buttonTitle = "Launch Recite Me";
    buttonContainer.setAttribute('id', 'reciteme-button');
    buttonContainer.setAttribute('alt', buttonAlt);
    buttonContainer.setAttribute('title', buttonTitle);
    var buttonParent = document.querySelector(buttonParentSelector);
    buttonParent.appendChild(buttonContainer);
    buttonContainer.addEventListener("click", function () {
        loadService();
        return false;
    });
    reciteMeButton = buttonContainer;
}
_createReciteButton();
