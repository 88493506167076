
export class TagManager {
    constructor(
        hostname,
        url,
        application,
        jobPostingId,
        brandCode,
        page
    ) {
        this.hostname = hostname;
        this.url = url;
        this.application = application;
        this.jobPostingId = jobPostingId;
        this.brandCode = brandCode;
        this.page = page;
    }

  get retrieveTags() {
    return this.getTrackingTags();
  }

  getTrackingTags() {
        const data = {
            hostname: this.hostname,
            url: this.url,
            application: this.application,
            jobPostingId: this.jobPostingId,
            brandCode: this.brandCode,
            page: this.page
        }

        return $.ajax({
            url: sessionStorage.getItem('apiHost') + 'TagTrackingTag/GetTrackingTagService',
            type: 'POST',
            data: JSON.stringify(data),
            contentType: "application/json",
            processData: false,
            success: function (response){
              console.log(response);
            },
            error: function (textStatus, errorThrown){
                console.log(errorThrown)
            }
        })
    }


  injectTag(script, position, parent) {
      if (position === "Top") {
        $(parent.toLowerCase()).prepend(script);
      } else {
        $(parent.toLowerCase()).append(script);
      }
    }
}
